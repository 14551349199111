const DataInicio = {
    logo: "https://mycvonlinebucket.s3.amazonaws.com/white-logo-transparent.png",
    logoTitle: "Alejandro Zapett",
    title: "Hola, ",
    description: [
        "Soy un estudiante de Ingeniería Mecatrónica de la UNAM. Me apasiona el desarrollo de nuevas tecnologías y cuento con poco más de tres años de experiencia en el desarrollo de aplicaciones híbridas con el enfoque serverless de AWS.",
        "Me interesa especialmente el desarrollo de sistemas espaciales, en específico el modelado, simulación y diseño de controladores para dispositivos robóticos.",
        "Puedes mandarme un email a alejandro.zapett@gmail.com si quieres que construya un impresionante sitio web, un increíble robot o tecnología del internet de las cosas."
    ],
    links: ['Resumen', 'Habilidades', 'Proyectos'],
    position: "Pasante de ingeniería mecatrónica"
}

export default DataInicio;