import './App.css';
import './Pages/pages.css';
import './Fonts/fonts.css';
import './Components/components.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Resume from './Pages/Resume';
import Skills from './Pages/Skills';
import Projects from './Pages/Projects';
import Inicio from './Pages/Inicio';

function App() {
  return (
    <Router>
      <div className="App">

      <Switch>
        <Route exact path="/" ><Inicio /></Route>
        <Route path="/resume" ><Resume /></Route>
        <Route path="/skills" ><Skills /></Route>
        <Route path="/projects" ><Projects /></Route>
      </Switch>

      </div>
    </Router>
  );
}

export default App;
