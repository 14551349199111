const DataProjects = [
    {
        name: 'ASTROMX',
        date: 'trabajo actual',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/astromx.jpeg',
        description: 'En colaboración con otros miembros de la agrupación estudiantil Cosmoblastos de la AAFI me encuentro desarrollando un robot asistente médico. El robot contará con un sistema de visión artificial, tecnología SLAM, reconocimiento de comandos de voz y módulos de actuación. El proyecto nace de la motivación de dar solución a los problemas que las condiciones de confinamiento trae a la salud de los astronautas.',
        position: 'left'
    },
    {
        name: 'Agrobot - App',
        date: 'enero 2021',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/agorbotapp.png',
        description: 'Es una aplicación web, dedicada al monitoreo continuo de un robot agricultor, permite la autenticación de usuarios, el manejo remoto del robot y la transmisión en vivo de video, entre otras funcionalidades. Las tecnologías que se utilizaron para la programación e implementación fueron React JS, GraphQL, AppSync, DynamoDB, Lambda Functions, Cognito, CloudFront, Route53, MQTTT y IotCore.',
        position: 'left'
    },
    {
        name: 'Agrobot - Control de posición',
        date: 'enero 2020',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/agrobotcontrolpos.png',
        description: 'Para el robot agricultor se diseñó e implementó un control de posición, el cuál está basado en un esquema de control en casacada. El primer lazo de control consiste en un control de velocidad PID para motores de corriente directa y el lazo externo se diseñó con la teoría de estabilidad de Lyapunov.',
        position: 'left'
    },
    {
        name: 'Agrobot - Visión artificial',
        date: 'enero 2020',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/visionartificial.png',
        description: 'El sistema de visión artificial se elaboró para extraer información del entorno para la recolección de frutos con el uso de un robot móvil y un manipulador robótico. Con el procesamiento de imágenes se obtiene la posición de determinados objetos. Para su implementación se utilizó una ESP32 CAM, un servidor linux, Python y la biblioteca OpenCV.',
        position: 'left'
    },
    {
        name: '2DOF - Control de posición',
        date: 'agosto 2020',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/2dofcontrol.png',
        description: 'Se diseñaron diferentes controladores para el control de posición de un robot de dos grados de libertad. Los esquemas de control que se abordaron fueron PD con compensador de gravedad, control basado en pasividad y par calculado. Para el banco de pruebas se utilizó un Arduino Due, motores de DC y un software de monitoreo.',
        position: 'left'
    },
    {
        name: '2DOF - Identificación paramétrica',
        date: 'agosto 2020',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/2dofestimacion.png',
        description: 'Para la obtención de un modelo matemático que describa el comportamiento de un brazo robótico de dos grados de libertad se llevó a cabo la obtención de parámetros con el uso del algoritmo de mínimos cuadrados recursivos. Los resultados se utilizaron para el diseño de controladores de posición.',
        position: 'left'
    },
    {
        name: '2DOF - Monitor',
        date: 'agosto 2020',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/2dofmonitor.png',
        description: 'El software de monitoreo es una aplicación de escritorio multiplataforma (Mac, Windows y Linux) dedicada a la obtención de datos provenientes de un robot de dos grados de libertad. El sistema genera reportes en formato CSV y gráficas en tiempo real. Utilizá comunicación serial para la conexión con un microcontrolador. Se programó con el uso de Electron y Angular.',
        position: 'left'
    },
    {
        name: '2DOF - Diseño mecánico',
        date: 'agosto 2020',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/2dofmechanics.png',
        description: 'Con la intención de tener un banco de pruebas para algoritmos de indetificación paramétrica y de control, se desarrolló el diseño mecánico de un robot de dos grados de libertad. Se utilizaron como componentes motores de DC, encoders ópticos y una estructura impresa en 3D.',
        position: 'left'
    },
    {
        name: 'Revista Reverberante',
        date: 'enero 2020',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/reverberante.png',
        description: 'Para la Revista Reverberante se desarrolló un sitio web que permitiera la publicación de anuncios y convocatorias. Además, se cuenta con una sección para la publicación de artículos digitales.',
        position: 'left'
    },
    {
        name: 'Tech Home',
        date: 'diciembre 2019',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/techhome.jpeg',
        description: 'Tech Home es una aplicación dedicada al manejo de dispositivos de IoT para el hogar. De entre sus funcionalidades destaca la autenticación de usuarios y el registro de dispositivos. Su programación e implementación se llevó a cabo con Angular y Firebase.',
        position: 'left'
    },
    {
        name: 'Inteligencia artificial: cuadro mágico',
        date: 'abril 2018',
        image: 'https://mycvonlinebucket.s3.amazonaws.com/Projects/cuadromagico.jpeg',
        description: 'Para la materia de Inteligencia Artificial de la Facultad de Ingeniería se desarrolló un programa que obtiene el procedimiento para la resolución del cuadro mágico. Se puede elegir entre tres algoritmos, el primero está basado en una búsqueda en árboles por amplitud y los dos siguientes en el uso de dos heurísticas diferentes.',
        position: 'left'
    }

]

export default DataProjects;