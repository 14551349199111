import React, {Component} from 'react';
import DataResume from '../Data/DataResume';

export default class ListaEducacion extends Component {

    state = {
        educacion:  [],
        width: 0
    }

    componentDidMount = () => {
        this.setState({
            educacion: DataResume.educacion,
            width: window.innerWidth
        });

        window.addEventListener('resize', ()=> {
            this.setState({width: window.innerWidth});
        });
    }

    componentWillUnmount = ()=> {
        window.removeEventListener('resize',()=>{});
    }

    render () {
        const {educacion} = this.state;

        return educacion.map((item) => {
            return (
                <div 
                    key={ item.school } 
                    className="trabajo-wrapper row"
                    style={{
                        margin: this.state.width <= 600 ? '10px 0':'10px 0 10px 10vw',
                        maxWidth: this.state.width <= 600 ? '100%':'90%',
                        padding: this.state.width <= 600 ? '0':'0 12px'
                    }}>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12 date">
                        <p>{item.date}</p>
                    </div>
                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-12 content">
                        <h6>{item.school}</h6>
                        <p>{item.career}</p>
                    </div>
                </div>
            );
        });
    }
}