import React, { Component } from 'react';
import DataSkills from '../Data/DataSkills';

export default class CADCAEList extends Component {

    state = {
        CADCAE: [],
        flag: false
    }

    componentDidMount = () => {
        this.setState({
            CADCAE: DataSkills.CADCAE
        });

        setTimeout(()=> {
            this.setState({flag: true});
        }, 700);
    }
    
    render() {
        const {CADCAE, flag} = this.state;

        return CADCAE.map((pl)=> {
            return(<div key = {pl.name} className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                    <p>{pl.name}</p>
                </div>
    
                <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-6 col-12">
                    <div className="bar-container">
                        <div className="bar-content blue" style={{width: flag ? pl.percentage+'%': '0' }}></div>
                    </div>
                </div>
            </div>)
        })
    }
}