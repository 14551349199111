import React, {Component} from 'react';
import Navbar from '../Components/Navbar';
import DataProjects from '../Data/DataProjects';

export default class Projects extends Component {

    state = {
        projects:[]
    }

    componentDidMount = () => {
        this.setState({projects: DataProjects});
    }

    render(){
        
        const {projects} = this.state;

        return (
            <div>
                <Navbar remark={'projects'} />

                <div className="grid projects-wrapper">
                    {projects.map((project)=>{
                        return (
                            <div className="row" key={project.name} >
                                <div 
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12"
                                    style={{display: project.position === 'left' ? 'block': 'none'}}>
                                    <div className="img-wrapper"><img src={project.image}/></div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                    <div className="texts-wrapper">
                                        <h2>{project.name}</h2>
                                        <p className="date">{project.date}</p>
                                        <p className="description">{project.description}</p>
                                    </div>
                                </div>
                                <div 
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12"
                                    style={{display: project.position === 'right' ? 'block': 'none'}}>
                                    <div className="img-wrapper"><img src={project.image}/></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}