import React, {Component} from 'react';
import Navbar from '../Components/Navbar';
import DataSkills from '../Data/DataSkills';
import ProgrammingList from '../Components/ProgrammingList';
import FrameworksList from '../Components/FrameworksList';
import CloudServicesList from '../Components/CloudServicesList';
import DataBasesList from '../Components/DataBasesList';
import CADCAEList from '../Components/CADCAEList';
import OtrosList from '../Components/OtrosList';
import LanguagesList from '../Components/LanguagesList';

export default class Skills extends Component {

    state = {
        titles: [],
        personalSkills: [],
        width: 0
    }

    componentDidMount = () => {
        this.setState({
            titles: DataSkills.titles,
            personalSkills: DataSkills.personalSkills,
            width: window.innerWidth
        });

        window.addEventListener('resize', ()=> {
            this.setState({width: window.innerWidth});
        });
    }

    componentWillUnmount = ()=> {
        window.removeEventListener('resize',()=>{});
    }

    render(){
        
        const {
            titles,
            personalSkills
        } = this.state;

        return (
            <div>
                <Navbar remark={'skills'} />

                <div className="grid skills-wrapper">
                    <div 
                        className="row skills-wrapper" 
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <p className="skill-title">{titles[0]}</p>
                        <div className="line" style={{display: this.state.width <= 600 ? 'none': 'block'}}></div>
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <ProgrammingList />
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <p className="skill-title">{titles[1]}</p>
                        <div    
                            className="line" 
                            style={{borderColor: '#33C5B9', display: this.state.width <= 600 ? 'none': 'block'}}></div>
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <FrameworksList />
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <p className="skill-title">{titles[2]}</p>
                        <div    
                            className="line" 
                            style={{borderColor: '#E22146', display: this.state.width <= 600 ? 'none': 'block'}}></div>
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <CloudServicesList />
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <p className="skill-title">{titles[3]}</p>
                        <div 
                            className="line" 
                            style={{borderColor: '#FFBD16', display: this.state.width <= 600 ? 'none': 'block'}}></div>
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <DataBasesList />
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <p className="skill-title">{titles[4]}</p>
                        <div 
                            className="line" 
                            style={{borderColor: '#33C5B9', display: this.state.width <= 600 ? 'none': 'block'}}></div>
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <CADCAEList />
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <p className="skill-title">{titles[5]}</p>
                        <div 
                            className="line" 
                            style={{borderColor: '#E22146', display: this.state.width <= 600 ? 'none': 'block'}}></div>
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <OtrosList />
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <p className="skill-title">{titles[6]}</p>
                        <div 
                            className="line" 
                            style={{borderColor: '#FFBD16', display: this.state.width <= 600 ? 'none': 'block'}}></div>
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <LanguagesList />
                    </div>

                     <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0':'0 8vw'}}>
                        <p className="skill-title">{titles[7]}</p>
                        <div 
                            className="line" 
                            style={{borderColor: '#33C5B9', display: this.state.width <= 600 ? 'none': 'block'}}></div>
                    </div>

                    <div 
                        className="row skills-wrapper"
                        style={{padding: this.state.width <= 600 ? '0 20px':'0 8vw'}}>
                        <ul>{personalSkills.map((item)=> {
                            return (<li key={item}><p>{item}</p></li>)
                        })}</ul>
                    </div>
                </div>
            </div>
        )
    }
}