const DataResume = {
    titles: ['Resumen', 'Experiencia laboral', 'Educación'],
    resume: 'Soy pasante de la carrera de Ingeniería mecatrónica y cuento con poco más de tres años de experiencia profesional en el desarrollo de software. Mi objetivo profesional es formar parte de equipos multidisciplinarios dedicados a la innovación tecnológica. Me interesa particularmente el desarrollo de sistemas robóticos e informáticos para sistemas espaciales.',
    jobs: [{
        date: '11/2019 - Presente',
        title: 'Ingeniero de control en Fotónica de México.',
        position: '',
        description: 'Realizo el diseño e implementación de algoritmos de control para el manejo de motores eléctricos.'
    },
    {
        date: '01/2020 - Presente',
        title: 'Desarrollador de software en Revista Reverberante.',
        position: '',
        description: 'Elaboré el diseño y la implementación de la revista digital de la empresa; actualmente me encargo del mantenimiento y actualizaciones del sistema.'
    },
    {
        date: '04/2018 - 05/2019',
        title: 'Desarrollador de softare en Asimov Ingeniería.',
        position: '',
        description: 'Desarrollé aplicaciones móviles y web con el uso del enfoque serverless para sistemas de internet de las cosas.'
    }],
    educacion: [{
        date:'06/2016 - Presente',
        school:'UNAM. Facultad de Ingeniería',
        career: 'Ingeniería mecatrónica'
    },
    {
        date:'10/2020 - Presente',
        school:'Asociación Aeroespacial de Facultad de Ingeniería. Cosmoblastos',
        career: 'Como integrante de la división de medicina espacial de la AAFI, me encuentro desarrollando el proyecto ASTROMX, el cual consiste en un robot asistente médico.'
    }]
}

export default DataResume;