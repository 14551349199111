const DataSkills = {
    titles: [
        'Lenguajes de programación', 
        'Frameworks', 
        'Servicios de cómputo en la nube', 
        'Bases de datos', 
        'CAD/CAE', 
        'Otros', 
        'Idiomas',
        'Habilidades personales'
    ],
    programmingLanguages: [{
        name: 'Python',
        percentage: '90'
    },
    {
        name: 'Javascript',
        percentage: '90'
    },
    {
        name: 'C',
        percentage: '80'
    },
    {
        name: 'C++',
        percentage: '70'
    },
    {
        name: 'Java',
        percentage: '70'
    },
    {
        name: 'Swift',
        percentage: '60'
    }],
    frameworks: [{
        name: 'Angular',
        percentage: '80'
    },
    {
        name: 'React JS',
        percentage: '90'
    },
    {
        name: 'Electron',
        percentage: '70'
    },
    {
        name: 'Ionic',
        percentage: '80'
    },
    {
        name: 'Django',
        percentage: '50'
    }],
    cloudServices: [{
        name: 'Amazon Web Services',
        percentage: '70'
    },
    {
        name: 'Google Cloud Platform',
        percentage: '60'
    },
    {
        name: 'Microsoft Azure',
        percentage: '40'
    }],
    dataBases: [{
        name: 'DynamoDB',
        percentage: '80'
    },
    {
        name: 'PostgresQL',
        percentage: '60'
    },
    {
        name: 'MySQL',
        percentage: '50'
    }],
    CADCAE: [{
        name: 'Inventor',
        percentage: '80'
    },
    {
        name: 'SolidWorks',
        percentage: '80'
    },
    {
        name: 'Fusion 360',
        percentage: '90'
    },
    {
        name: 'Ansys',
        percentage: '40'
    }],
    others: [{
        name: 'GraphQL',
        percentage: '80'
    },
    {
        name: 'MATLAB/Simulink',
        percentage: '90'
    },
    {
        name: 'Git/Github',
        percentage: '80'
    },
    {
        name: 'Linux',
        percentage: '60'
    }],
    languages: [{
        name: 'Inglés',
        percentage: '80'
    },
    {
        name: 'Alemán',
        percentage: '10'
    }],
    personalSkills : [
        'Facilidad de comunicación oral y escrita',
        'Creatividad',
        'Pensamiento crítico',
        'Capacidad de colaboración en equipo'
    ]
}

export default DataSkills;