import React, { Component } from 'react';
import DataInicio from '../Data/DataInicio';
import { Link } from 'react-router-dom';

export default class Navbar extends Component {
    state = {
        title: "",
        position: "",
        links: "",
        remark: "",
        width: ''
    }

    componentDidMount = ()=> {
        this.setState({
            title: DataInicio.logoTitle,
            position: DataInicio.position,
            links: DataInicio.links,
            remark: this.props.remark,
            width: window.innerWidth
        });

        window.addEventListener('resize', () => {
            this.setState({width: window.innerWidth});
        });
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', ()=>{});
    }

    render(){

        const {
            title,
            position,
            links,
            remark,
            width
        } = this.state;

        return (
            <div className="navbar-component row">
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-12">
                    <div className="items-wrapper">
                        <Link to="/"><h2>{title}</h2></Link>
                        <h4>{position}</h4>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12" 
                     style={{display: width <= 770 ? 'none': 'block'}}>
                    <div className="links-wrapper">
                        <Link 
                            to="/resume"
                            style={{color: remark === 'resume' ? '#E22146': 'black'}}>{links[0]}</Link>
                        <Link 
                            to="/skills"
                            style={{color: remark === 'skills' ? '#FFBD16': 'black'}}>{links[1]}</Link>
                        <Link 
                            to="/projects"
                            style={{color: remark === 'projects' ? '#33C5B9': 'black'}}>{links[2]}</Link>
                    </div>
                </div>

                <div className="col-12" style={{display: width <= 770 ? 'block': 'none'}}>
                    <div className="links-wrapper" style={{margin: '0', padding: '0'}}>
                        <Link 
                            to="/resume"
                            style={{color: remark === 'resume' ? '#E22146': 'black'}}>{links[0]}</Link>
                        <Link 
                            to="/skills"
                            style={{color: remark === 'skills' ? '#FFBD16': 'black'}}>{links[1]}</Link>
                        <Link 
                            to="/projects"
                            style={{color: remark === 'projects' ? '#33C5B9': 'black'}}>{links[2]}</Link>
                    </div>
                </div>
            </div>
        )
    }
}