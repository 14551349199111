import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import DataResume from '../Data/DataResume';
import Trabajos from '../Components/Trabajos';
import ListaEducacion from '../Components/ListaEducacion';

export default class Resume extends Component {
    
    state = {
        titles: [],
        resume: '',
        width: 0
    }

    componentDidMount = ()=> {
        this.setState({
            titles: DataResume.titles,
            resume: DataResume.resume,
            width: window.innerWidth
        });

        window.addEventListener('resize', ()=> {
            this.setState({width: window.innerWidth});
        });
    }

    componentWillUnmount = ()=> {
        window.removeEventListener('resize',()=>{});
    }

    render() {

        const {
            titles,
            resume,
            width
        } = this.state;

        return (
            <div>
                <Navbar remark={'resume'} />

                <div className="resume-wrapper grid">
                    <div className="row profile-wrapper">
                        <div className="num-circle"><p>01</p></div>
                        <div className="resume-title"><p>{titles[0]}</p></div>
                        <div 
                            style={{width: '120px', display: this.state.width <= 600 ? 'none': 'block'}} 
                            className="line"></div>
                    </div>

                    <div 
                        className="row profile-wrapper">
                        <p 
                            className="inner-text"
                            style={{
                                margin: this.state.width <= 600 ? '10px 10px':'10px 0 10px 10vw',
                                maxWidth: this.state.width <= 600 ? '100%':'90%'
                            }}>{resume}</p>
                    </div>

                    <div className="row profile-wrapper">
                        <div className="num-circle"><p>02</p></div>
                        <div className="resume-title"><p>{titles[1]}</p></div>
                        <div 
                            style={{width: '220px', display: this.state.width <= 600 ? 'none': 'block'}} 
                            className="line"></div>
                    </div>

                    <div className="row profile-wrapper">
                        <Trabajos />
                    </div>

                    <div className="row profile-wrapper">
                        <div className="num-circle"><p>03</p></div>
                        <div className="resume-title"><p>{titles[2]}</p></div>
                        <div 
                            style={{width: '130px', display: this.state.width <= 600 ? 'none': 'block'}} 
                            className="line"></div>
                    </div>

                    <div className="row profile-wrapper">
                        <ListaEducacion />
                    </div>
                </div>
            </div>
        )
    }
}