import React, {Component} from 'react';
import DataInicio from '../Data/DataInicio';
import { Link } from 'react-router-dom';

export default class Inicio extends Component {

    state = {
        logoTitle: "",
        logoUrl: "",
        title: "",
        description: "",
        links: []
    }

    componentDidMount = () => {
        this.setState({
            logoTitle: DataInicio.logoTitle,
            logoUrl: DataInicio.logo,
            title: DataInicio.title,
            description: DataInicio.description,
            links: DataInicio.links
        });
    }

    render(){

        const {
            logoTitle, 
            logoUrl,
            title,
            description,
            links
        } = this.state;

        return (
            <div className="inicio-component row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 black-box">
                    <div className="items-wrapper">
                        <img src={logoUrl} alt="logo"/>
                        <p>{logoTitle}</p> 
                    </div>   
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 white-box">
                    <div className="items-wrapper">
                        <div className="upper-links">
                            <Link to="/resume">{links[0]}</Link>
                            <Link to="/skills">{links[1]}</Link>
                            <Link to="/projects">{links[2]}</Link>
                        </div>
                        <div className="texts">
                            <h2>{title}</h2>
                            <p>{description[0]}</p>
                            <p>{description[1]}</p>
                            <p>{description[2]}</p>
                        </div>
                        <div className="lower-links">
                            <div className="circle red"><Link to="/resume">{links[0]}</Link></div>
                            <div className="circle yellow"><Link to="/skills">{links[1]}</Link></div>
                            <div className="circle blue"><Link to="/projects">{links[2]}</Link></div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}